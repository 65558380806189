import React, { useState, useEffect } from 'react';
import '../styles.css';
import { BusinessTypeAPI } from '../apis/api/traxid/BusinessTypeAPI';
import { RequestTypeAPI } from '../apis/api/traxid/RequestTypeAPI';
import { InterestSolutionAPI } from '../apis/api/traxid/InterestSolutionAPI';

export default function DropDownTable({ activeButton }) {
    const [data, setData] = useState([]);
    const [editedValues, setEditedValues] = useState({ id: null, value: '', text: '' });
    const [isAdding, setIsAdding] = useState(false);
    const [error, setError] = useState("");

    const fetchData = async () => {
        try {
            let fetchedData = [];

            if (activeButton.text === 'Business Type') {
                fetchedData = await BusinessTypeAPI.getAllBusinessTypes();
            } else if (activeButton.text === 'Request Type') {
                fetchedData = await RequestTypeAPI.getAllRequestTypes();
            } else if (activeButton.text === 'Interest Solutions') {
                fetchedData = await InterestSolutionAPI.getAllInterestSolutions();
            }

            setData(fetchedData);
        } catch (error) {
            console.error(`Error fetching ${activeButton.text} data:`, error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [activeButton]);

    const handleEdit = (id) => {
        const selectedItem = data.find(item => item.id === id);
        setEditedValues({ id, value: selectedItem.value, text: selectedItem.text });
        setError('');
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedValues(prevValues => ({ ...prevValues, [name]: value }));
    };


    const handleSave = async () => {
        try {
            const isDuplicate = data.some(item => item.value === editedValues.value && item.text === editedValues.text);

            if (isDuplicate) {
                // Display an error message or handle the duplicate case as needed
                setError('The value and text already exists.');
                return;
            }
            if (activeButton.text === 'Business Type') {
                await BusinessTypeAPI.updateBusinessType(editedValues.id, {
                    value: editedValues.value,
                    text: editedValues.text
                });
            } else if (activeButton.text === 'Request Type') {
                await RequestTypeAPI.updateRequestType(editedValues.id, {
                    value: editedValues.value,
                    text: editedValues.text
                });
            } else if (activeButton.text === 'Interest Solutions') {
                await InterestSolutionAPI.updateInterestSolution(editedValues.id, {
                    value: editedValues.value,
                    text: editedValues.text
                });
            }

            // Refresh the data after successful update
            fetchData();
            setEditedValues({ id: null, value: '', text: '' });
            setError("");
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            // Show confirmation dialog
            const isConfirmed = window.confirm('Are you sure you want to delete this item?');

            if (isConfirmed) {
                if (activeButton.text === 'Business Type') {
                    await BusinessTypeAPI.deleteBusinessType(id);
                } else if (activeButton.text === 'Request Type') {
                    await RequestTypeAPI.deleteRequestType(id);
                } else if (activeButton.text === 'Interest Solutions') {
                    await InterestSolutionAPI.deleteInterestSolution(id);
                }
            }

            // Refresh the data after successful delete
            fetchData();
        } catch (error) {
            console.error('Error deleting data:', error);
        }
    };

    const handleCancel = () => {
        setEditedValues({ id: null, value: '', text: '' });
        setError('');
    }

    const handleAdd = () => {
        setIsAdding(true);
        setEditedValues({ id: null, value: '', text: '' });
        setError(''); 
    };

    const handleCancelAdd = () => {
        setIsAdding(false);
        setEditedValues({ id: null, value: '', text: '' });
        setError("");
    };

    const handleSaveAdd = async () => {
        try {
            const isDuplicate = data.some(item => item.value === editedValues.value || item.text === editedValues.text);

            if (isDuplicate) {
                // Display an error message or handle the duplicate case as needed
                setError('Duplicate values or texts are not allowed.');
                return;
            }
            if (activeButton.text === 'Business Type') {
                await BusinessTypeAPI.addBusinessType({ value: editedValues.value, text: editedValues.text });
            } else if (activeButton.text === 'Request Type') {
                await RequestTypeAPI.addRequestType({ value: editedValues.value, text: editedValues.text });
            } else if (activeButton.text === 'Interest Solutions') {
                await InterestSolutionAPI.addInterestOption({ value: editedValues.value, text: editedValues.text });
            }

            // Refresh the data after successful add
            fetchData();
            setIsAdding(false);
            setEditedValues({ id: null, value: '', text: '' });
            setError('');
        } catch (error) {
            console.error('Error adding data:', error);
        }
    };

    return (
        <>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Value</th>
                        <th scope="col">Text</th>
                        <th scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody className="table-group-divider">
                    {data.length > 0 ? (
                        data.map((item, index) => (
                            <tr key={index}>
                                <td>{item.id}</td>
                                <td>{editedValues.id === item.id ? (
                                    <input
                                        type="text"
                                        name="value"
                                        value={editedValues.value}
                                        onChange={handleInputChange}
                                    />
                                ) : (
                                    item.value
                                )}
                                </td>
                                <td>{editedValues.id === item.id ? (
                                    <input
                                        type="text"
                                        name="text"
                                        value={editedValues.text}
                                        onChange={ handleInputChange }
                                    />

                                ) :(item.text)}</td>
                                <td>
                                    {editedValues.id === item.id ? (
                                        <>
                                            <button className="btn btn-outline-success ddl_btn_left" onClick={handleSave} type="button">SAVE</button>
                                            <button className="btn btn-outline-warning" onClick={handleCancel} type="button">CANCEL</button>
                                        </>
                                    ) : (
                                            <>
                                                <button
                                                    className="btn btn-outline-primary ddl_btn_left"
                                                    onClick={() => handleEdit(item.id)}
                                                    type="button"
                                                    disabled={isAdding}
                                                >
                                                    EDIT
                                                </button>
                                                <button
                                                    className="btn btn-outline-danger"
                                                    onClick={() => handleDelete(item.id)}
                                                    type="button"
                                                    disabled={isAdding}
                                                >
                                                    DELETE
                                                </button>
                                            </>
                                    )}

                                </td>
                            </tr>
                        ))
                    ) : activeButton !== "" && (
                            <tr>
                                <td colSpan="3">No data available for the selected type.</td>
                            </tr>
                    )}
                </tbody>
            </table>
            {activeButton !== "" && (
                <div>
                    <button className="btn btn-outline-secondary" onClick={handleAdd}>ADD</button>
                </div>
            )}
            {isAdding && (
                <div>
                    <input
                        type="text"
                        name="value"
                        placeholder="Value"
                        value={editedValues.value}
                        onChange={handleInputChange}
                        style={{ margin: '5px', width: '200px' }}
                    />
                    <input
                        type="text"
                        name="text"
                        placeholder="Text"
                        value={editedValues.text}
                        onChange={handleInputChange}
                        style={{ margin: '5px', width: '200px' }}
                    />
                    <button className="btn btn-outline-success ddl_btn_left" onClick={handleSaveAdd}>SAVE</button>
                    <button className="btn btn-outline-warning" onClick={handleCancelAdd}>CANCEL</button>
                </div>
            )}
            <div>
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </div>

        </>
    
    );
}