import React from 'react';
import { Link } from 'react-router-dom';
import '../styles.css';
import ContactForm from './ContactForm';
import Footer from './Footer';


function EnterpriseLanding() {
    return (
        <>
            {/* black back ground */}
            <div className="container-fluid  halfBlack-bg">
                <div className="row">
                    <div className="col-xl-3 col-lg-2 col-md-2 col-sm-1">
                    </div>
                    <div className="col-xl-6 col-lg-8 col-md-8 col-sm-10 align-items-center mt-5 mb-3">
                        <img src={require("../images/EnterpriseWorkflow.png")} alt="work flow" className="img-fluid enterprise-1-workflow mt-3" />
                    </div>
                </div>

                <div className="row text-center">
                    <div className="col-xl-3 col-lg-2 col-md-2 col-sm-1"></div>
                    <div className="col-xl-6 col-lg-8 col-md-8 col-sm-10">
                        <h2 className="header mt-3 mb-3 landing-header">
                            We Take Your Operations and Help You Handle Them Smoothly <span className="orange-text">From Beginning to End</span>
                        </h2>
                    </div>
                </div>

                {/* work image, half black background */}
                <div className="row container-fluid">
                    <div className="col-xl-2 col-lg-1"></div>
                    <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 col-xs-12 mb-4 p-3">
                        <img src={require("../images/TraxWarehouse.png")} alt="TraxWarehouse" className="img-fluid enterprise-1-workflow mt-3" />
                        <p className="mt-4">Our <span className="orange-text">free enterprise guide</span> illustrates what's capable with TraxID solutions on your side. We help with every part of your workflow from operation managers, billing personnel, to executives. Get real-time updates on productivity, revenue, and incidents, accessible from anywhere.
                        </p>
                        <p>Learn about some of our existing modules we've created for other large enterprises including:</p>
                        <div className="row">
                            <div className="col-lg-1"></div>
                            <div className="col-lg-10 col-md-12 col-sm-12 col-xs-12">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <span className="orange-text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                        </svg></span> Sales
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <span className="orange-text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                        </svg></span> Work Crew
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <span className="orange-text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                        </svg></span> Inventory
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <span className="orange-text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                        </svg></span> Analytics
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <span className="orange-text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                        </svg></span> Work Orders
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <span className="orange-text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                        </svg></span> Billing
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <span className="orange-text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                        </svg></span> Servicing
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <span className="orange-text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                        </svg></span> Purchases
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                                        <span className="orange-text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                        </svg></span> Customer Portal
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 col-xs-12">
                        <div style={{ background: 'white', borderRadius: '5%', padding: '10px 20px' }} className="mt-md-5 mt-sm-1 mt-xs-1">
                            <ContactForm selectedReq="guide" selectedInterest="enterprise" hideDdl={true} />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-2 col-md-2 col-sm-1 col-xs-1"></div>
                    <div className="col-lg-8 col-md-8 col-sm-10 col-xs-10 text-center mt-3">
                        <p>Not a large enterprise? Watch our 
                            <Link to="/standard" className="orange-text no-underline"> free demo</Link> for our TraxField solution for small businesses.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />

        </>
    );

}

export default EnterpriseLanding;