import React, { useState, useEffect } from "react";
import "../styles.css";
//import businessData from '../DropDownOptions/businessType.json';
//import requestData from '../DropDownOptions/requestType.json';
//import interestData from '../DropDownOptions/interestSolution.json';
import { ContactFormAPI } from "../apis/api/traxid/ContactFormAPI";
import { BusinessTypeAPI } from "../apis/api/traxid/BusinessTypeAPI";
import { RequestTypeAPI } from "../apis/api/traxid/RequestTypeAPI";
import { InterestSolutionAPI } from "../apis/api/traxid/InterestSolutionAPI";

function ContactForm({ selectedReq, selectedInterest, hideDdl }) {
  const [businessTypes, setBusinessTypes] = useState([]);
  const [requestTypes, setRequestTypes] = useState([]);
  const [interestSolutions, setInterestSolutions] = useState([]);
  const [message, setMessage] = useState({ isVisible: false, type: "" });

  useEffect(() => {
    async function fetchBusinessTypes() {
      try {
        const types = await BusinessTypeAPI.getAllBusinessTypes();
        setBusinessTypes(types);
      } catch (error) {
        console.error("Error fetching business types:", error);
      }
    }

    async function fetchRequestTypes() {
      try {
        const types = await RequestTypeAPI.getAllRequestTypes();
        setRequestTypes(types);
      } catch (error) {
        console.error("Error fetching request types:", error);
      }
    }

    async function fetchInterestSolutions() {
      try {
        const solutions = await InterestSolutionAPI.getAllInterestSolutions();
        setInterestSolutions(solutions);
      } catch (error) {
        console.error("Error fetching interest solutions:", error);
      }
    }

    fetchRequestTypes();
    fetchInterestSolutions();
    fetchBusinessTypes();
  }, []);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    company_name: "",
    business_type: "",
    company_web: "",
    type: selectedReq,
    interest: selectedInterest,
    how_can_we_help: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await ContactFormAPI.createContactRequest(formData);
      console.log("Form submitted successfully");
      setFormData({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        company_name: "",
        business_type: "",
        company_web: "",
        type: selectedReq,
        interest: selectedInterest,
        how_can_we_help: "",
      });

      // Show the success message
      setMessage({ isVisible: true, type: "success" });

      // Hide the message after a delay (e.g., 5 seconds)
      setTimeout(() => {
        setMessage(false);
      }, 5000);
    } catch (error) {
      console.error("Form submission error:", error);
      setMessage({ isVisible: true, type: "error" });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <fieldset>
          <legend>Your Information</legend>
          <div className="row mb-3">
            <div className="col-md-6 mb-3 mb-md-0">
              <input type="text" className="form-control" id="firstName" placeholder="First Name*" name="first_name" value={formData.first_name} onChange={handleChange} required />
            </div>
            <div className="col-md-6">
              <input type="text" className="form-control" id="lastName" placeholder="Last Name*" name="last_name" value={formData.last_name} onChange={handleChange} required />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6 mb-3 mb-md-0">
              <input type="email" className="form-control" id="businessEmail" placeholder="Business Email*" name="email" value={formData.email} onChange={handleChange} required />
            </div>
            <div className="col-md-6">
              <input type="tel" className="form-control" id="phoneNumber" placeholder="Phone" name="phone" value={formData.phone} onChange={handleChange} />
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>Company Information</legend>
          <div className="row mb-3">
            <div className="col-md-6 mb-3 mb-md-0">
              <input type="text" className="form-control" id="companyName" placeholder="Company Name" name="company_name" value={formData.company_name} onChange={handleChange} />
            </div>
            <div className="col-md-6">
              <input type="url" className="form-control" id="companyWebsite" name="company_web" placeholder="Company Website" value={formData.company_web} onChange={handleChange} />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <select className="form-select" id="businessType" name="business_type" value={formData.business_type} onChange={handleChange}>
                <option value="" className="placeholder-option">
                  Select Business Type...
                </option>
                {businessTypes.map((option) => (
                  <option key={option.id} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>Your Message</legend>
          {!hideDdl && (
            <div className="row mb-3">
              <div className="col-md-6 mb-3 mb-md-0">
                <select className="form-select" id="requestType" name="type" value={formData.request_type} onChange={handleChange}>
                  <option value="" className="placeholder-option">
                    Select Request Type...
                  </option>
                  {requestTypes.map((option) => (
                    <option key={option.id} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6">
                <select className="form-select" id="interestType" name="interest" value={formData.interest} onChange={handleChange}>
                  <option value="" className="placeholder-option">
                    Choose Your Interest...
                  </option>
                  {interestSolutions.map((option) => (
                    <option key={option.id} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
          <div className="mb-3">
            <textarea className="form-control" id="howCanWeHelp" rows="3" placeholder="How can we help?" name="how_can_we_help" value={formData.how_can_we_help} onChange={handleChange}></textarea>
          </div>
        </fieldset>

        <button type="submit" className="btn form-btn">
          Submit
        </button>
      </form>
      {message.isVisible && (
        <div className={`alert mt-3 ${message.type === "success" ? "alert-success" : "alert-danger"}`} role="alert">
          {message.type === "success" ? "Your request has been sent!" : "An error occurred. Please try again."}
        </div>
      )}
    </>
  );
}

export default ContactForm;
