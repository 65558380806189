import React from "react";
import "../styles.css";
import ContactForm from "./ContactForm";
import Footer from "./Footer";

function AboutPage() {
  return (
    <>
      {/* black back ground with tabletApp */}

      <div className="container-fluid darkbg">
        <div className="row text-center  mb-4">
          <div className="col-lg-2 col-md-1"></div>
          <div className="col-lg-8 col-md-10 col-sm-12  mb-4 mt-4">
            <h2 className="header mt-4">
              We're Committed to <span className="orange-text">Adding Value</span> to Your Inspection Business
            </h2>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-3 col-md-2"></div>
          <div className="col-lg-6 col-md-8 col-sm-12 mb-4 text-center">
            <img src={require("../images/TabletApps.png")} alt="TabletApps" className="img-fluid about-tablet" />
          </div>
        </div>
      </div>
      {/* end of black with tablet section */}

      <div className="container-fluid content-font">
        <div className="row mt-5 mb-5">
          <div className="col-lg-1"></div>
          <div className="col-lg-5 col-md-5 col-sm-4 col-xs-12">
            <img src={require("../images/TraxTableTools_Cropped.png")} alt="TraxTableTools" className="img-fluid about-tableTools" />
          </div>
          <div className="col-lg-5 col-md-7 col-sm-8 col-xs-12 tabgroup">
            <ul className="nav nav-pills mb-3 nav-justified" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button className="nav-link tab-button active" id="pills-mission-tab" data-bs-toggle="pill" data-bs-target="#pills-mission" type="button" role="tab" aria-controls="pills-OurMission" aria-selected="true">
                  <span className="d-none d-sm-inline tab-our">Our </span>Mission
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link tab-button" id="pills-experience-tab" data-bs-toggle="pill" data-bs-target="#pills-experience" type="button" role="tab" aria-controls="pills-OurExperience" aria-selected="false">
                  <span className="d-none d-sm-inline tab-our">Our </span>Experience
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link tab-button" id="pills-value-tab" data-bs-toggle="pill" data-bs-target="#pills-value" type="button" role="tab" aria-controls="pills-OurValue" aria-selected="false">
                  <span className="d-none d-sm-inline tab-our">Our </span>Value
                </button>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div className="tab-pane fade show active" id="pills-mission" role="tabpanel" aria-labelledby="pills-mission-tab" tabIndex="0">
                Our team focuses on building simple-to-use and cost-effective solutions to solve your business needs without spending an excessive amount of time in learning how to use the system, and without the rigid software processes as many ERP systems do.
              </div>
              <div className="tab-pane fade" id="pills-experience" role="tabpanel" aria-labelledby="pills-experience-tab" tabIndex="0">
                TraxID brings decades of hardware and software expertise to provide customizable enterprise software solutions for companies managing critical operations worldwide. With customers from both the US and internationally, we bring a commitment to delivering reliable and innovative solutions for any business needs that arise. What sets us apart is our unwavering commitment to swift customer response and exceptional support, receiving positive feedback from our satisfied clients.
              </div>
              <div className="tab-pane fade" id="pills-value" role="tabpanel" aria-labelledby="pills-value-tab" tabIndex="0">
                TraxID excels in swiftly adapting to evolving business needs through our user-friendly and cost-effective platform. With a successful track record, particularly in international oilfield equipment inspections, we bring a commitment to delivering reliable and efficient technology solutions with prompt responses and exceptional customer support.
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Contact Us */}
      <div className="container-fluid content-font">
        <div className="row mt-4 mb-1  text-center">
          <div className="col-lg-2 col-md-1"></div>
          <div className="col-lg-8 col-md-10 col-sm-12">
            <h2 className="header">Contact Us</h2>
            <p>Let us get to know your business and how we can help add value. Reach out to us via the contact form below and our sales representative will be in touch with you shortly.</p>
          </div>
        </div>

        <div className="row mb-4 content-font">
          <div className="col-lg-2 col-md-1"></div>
          <div className="col-lg-8 col-md-10 col-sm-12">
            <ContactForm selectedReq="" selectedInterest="" hideDdl={false} />
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row mt-5 mb-2  text-center">
          <div className="col-lg-2 col-md-1"></div>
          <div className="col-lg-8 col-md-10 col-sm-12">
            <h2 className="lg-text header">Get in Touch</h2>
          </div>
        </div>

        <div className="row mt-4 mb-5  text-center">
          <div className="col-lg-2 col-md-1"></div>
          <div className="col-lg-8 col-md-10 col-sm-12">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-4 orange-border">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-telephone" viewBox="0 0 16 16">
                  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                </svg>
                <p className="contact-info">469.778.1442</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 orange-border">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-geo-alt" viewBox="0 0 16 16">
                  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                </svg>
                <p className="contact-info">12100 Ford Road, #302, Dallas, TX 75234</p>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                </svg>
                <p className="contact-info">info@traxid.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AboutPage;
