import React, { useState, useEffect } from 'react';
import '../styles.css';
import { RequestAPI } from '../apis/api/traxid/RequestAPI';
import Pagination from './Pagination.js';

export default function RequestTable({ activeCategory, activeButton, onRowClick, setNewStatusSpan }) {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch requests based on the activeButton's corresponding value
                const requests = await RequestAPI.getRequestsByType(activeButton.value);
                setData(requests);

                const isNewRequestPresent = requests.some(request => request.status === 'New');

                // Update the state for the specific button value
                setNewStatusSpan(prevStatus => ({
                    ...prevStatus,
                    [activeButton.value]: isNewRequestPresent,
                }));
            } catch (error) {
                console.error('Error fetching request data:', error);
            }
        };

        if (activeButton) {
            fetchData();
        }
    }, [activeButton]);

    // Get current items based on current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(data.length / itemsPerPage);

    const onPageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    return (
        <>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Status</th>
                        <th scope="col">Interest</th>
                        <th scope="col">Time</th>
                    </tr>
                </thead>
                <tbody className="table-group-divider">
                    {currentItems.length > 0 ? (
                        currentItems.map((item, index) => (
                            <tr className="request_row"
                                key={index}
                                onClick={() => onRowClick(item.req_id)}
                            >
                                <td>{item.req_id}</td>
                                <td>{item.status}</td>
                                <td>{item.interest}</td>
                                <td>{item.req_time}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="4">No data available for the selected category and button.</td>
                        </tr>
                    )}
                </tbody>
            </table>
            {data.length > 0 && (
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={onPageChange}
                />
            )}
        </>
    );
}