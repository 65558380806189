import { apiClient } from '../../utils/instances';

const URI = '/api/interestsolutions';

export const InterestSolutionAPI = {
    getAllInterestSolutions: async () => {
        try {
            const response = await apiClient.get(URI);
            return response.data;
        } catch (error) {
            console.error('API [getAllInterestSolutions] Error:', error);
            throw error;
        }
    },

    addInterestOption: async (newOption) => {
        try {
            const response = await apiClient.post(URI, newOption);
            return response.data;
        } catch (error) {
            console.error('API [addInterestOption] Error:', error);
            throw error;
        }
    },

    updateInterestSolution: async (id, updatedSolution) => {
        try {
            const response = await apiClient.put(`${URI}/${id}`, updatedSolution);
            return response.data;
        } catch (error) {
            console.error('API [updateInterestSolution] Error:', error);
            throw error;
        }
    },

    deleteInterestSolution: async (id) => {
        try {
            const response = await apiClient.delete(`${URI}/${id}`);
            return response.data;
        } catch (error) {
            console.error('API [deleteInterestSolution] Error:', error);
            throw error;
        }
    },
};
