import { apiClient } from '../../utils/instances';

const URI = '/api/requesttypes';

export const RequestTypeAPI = {
    getAllRequestTypes: async () => {
        try {
            const response = await apiClient.get(URI);
            return response.data;
        } catch (error) {
            console.error('API [getAllRequestTypes] Error:', error);
            throw error;
        }
    },

    addRequestType: async (newType) => {
        try {
            const response = await apiClient.post(URI, newType);
            return response.data;
        } catch (error) {
            console.error('API [addRequestType] Error:', error);
            throw error;
        }
    },

    updateRequestType: async (id, updatedRequestType) => {
        try {
            const response = await apiClient.put(`${URI}/${id}`, updatedRequestType);
            return response.data;
        } catch (error) {
            console.error('API [updateRequestType] Error:', error);
            throw error;
        }
    },

    deleteRequestType: async (id) => {
        try {
            const response = await apiClient.delete(`${URI}/${id}`);
            return response.data;
        } catch (error) {
            console.error('API [deleteRequestType] Error:', error);
            throw error;
        }
    },
};
