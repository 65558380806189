import axios from "axios";

//const ORIGIN = process.env.REACT_APP_ORIGIN
const API_BASE_URL = process.env.REACT_APP_ORIGIN;

export const apiClient = axios.create({
  baseURL: API_BASE_URL,
  timeout: 5000,
});

//export const apiAuthClient = (token) => axios.create({
//    baseURL: ORIGIN,
//    headers: { Authorization: `bearer ${token}` },
//});

// export const apiPostClient = axios.create({
//     baseURL: ORIGIN,
//     headers: { 'Content-Type': 'application/json' },
// });
