import React, { useState, useEffect } from 'react';
import { RequestDetailsAPI } from '../apis/api/traxid/RequestDetailsAPI';
import { RequestAPI } from '../apis/api/traxid/RequestAPI';
import '../styles.css';

export default function RequestDetails({ requestId, onClose, setDisableButtons }) {
    const adminData = localStorage.getItem('adminData');
    const admin = JSON.parse(adminData);

    const TimeAgo = ({ reqTime }) => {
        const [timeAgo, setTimeAgo] = useState('');

        useEffect(() => {
            const calculateTimeAgo = () => {
                const reqDate = new Date(reqTime);
                const currentDate = new Date();
                const timeDifference = currentDate - reqDate;
                const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
                const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

                let timeAgoString = '';
                if (days > 0) {
                    timeAgoString += days + ' days';
                }
                if (hours > 0) {
                    timeAgoString += (timeAgoString.length > 0 ? ' ' : '') + hours + ' hours';
                }

                setTimeAgo(timeAgoString.length > 0 ? timeAgoString + ' ago' : 'Just now');
            };

            calculateTimeAgo();

            // Update the time every minute (adjust as needed)
            const intervalId = setInterval(calculateTimeAgo, 60000);

            return () => clearInterval(intervalId);
        }, [reqTime]);

        return (
            <div className="card-footer detail_footer">
                {timeAgo}
            </div>
        );
    };

    const [details, setDetails] = useState({});
    const [replyText, setReplyText] = useState('');

    useEffect(() => {
        const fetchDetails = async () => {
            try {
                // Fetch additional details using the requestId
                const detailsData = await RequestDetailsAPI.getRequestDetails(requestId);
                setDetails(detailsData);
            } catch (error) {
                console.error('Error fetching request details:', error);
            }
        };

        fetchDetails();
    }, [requestId]);

    useEffect(() => {
        // Set disableButtons to true once the component is rendered
        setDisableButtons(true);
    }, []); // Empty dependency array to run this effect only once

    const handleReply = async () => {
        try {
            if (!replyText || replyText.trim() === '') {
                // If replyText is null or empty, return from the function
                return;
            }
            // Send reply using the ReplyAPI
            //await ReplyAPI.sendReply(requestId, replyText);
            // Close the details component or perform any other necessary actions
            const existingRequest = await RequestAPI.getRequestById(requestId);
            const updatedRequest = {
                ...existingRequest,
                resolved_by: admin.admin_id,
                reply: replyText,
                status: 'Completed',
            };
            console.log(updatedRequest);
            await RequestAPI.updateRequest(requestId, updatedRequest);
            return;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.errors) {
                console.error('Validation errors:', error.response.data.errors);
            } else {
                console.error('Error sending reply:', error);
            }

        }
    };

    const handleClose = () => {
        onClose();
        setDisableButtons(false);
    }

    return (
        <div className="card text-center">
            <div className="card-header detail_header d-flex justify-content-between">
                <span>Request Details</span>
                <span>
                    <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
                </span>
            </div>

            <div className="card-body detail_body">
                <p className="card-text"><span className="detail_bold">Request ID:</span> {details.req_id}</p>
                <p className="card-text"><span className="detail_bold">Status:</span> {details.status}</p>
                <p className="card-text"><span className="detail_bold">First Name:</span> {details.first_name}</p>
                <p className="card-text"><span className="detail_bold">Last Name:</span> {details.last_name}</p>
                <p className="card-text"><span className="detail_bold">Email:</span> {details.email}</p>
                <p className="card-text"><span className="detail_bold">Phone:</span> {details.phone}</p>
                <p className="card-text"><span className="detail_bold">Company:</span> {details.company_name}</p>
                <p className="card-text"><span className="detail_bold">Business Type:</span> {details.business_type}</p>
                <p className="card-text"><span className="detail_bold">Interest:</span> {details.interest}</p>
                <p className="card-text"><span className="detail_bold">Message:</span> {details.how_can_we_help}</p>
                <textarea
                    className="form-control"
                    placeholder="Reply the message here."
                    value={details.status === 'Completed' ? details.reply: replyText}
                    onChange={(e) => setReplyText(e.target.value)}
                    disabled={details.status === 'Completed'}
                />
                <button onClick={handleReply} className="btn btn-secondary mt-3" disabled={details.status === 'Completed'}>Reply</button>
            </div>
            <TimeAgo reqTime={details.req_time} />
        </div>
    );
}