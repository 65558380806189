import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import "../styles.css";

function CookiePopup() {
  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    const cookieConsent = Cookies.get("cookieConsent");
    if (!cookieConsent) {
      setShowPopup(true);
    }
  }, []);

  const handleAccept = () => {
    Cookies.set("cookieConsent", "true", { expires: 30 });
    setShowPopup(false);
  };

  const handleDecline = () => {
    // Add logic here to handle the decline action, maybe restrict certain functionalities or show a message
    console.log("Cookies declined!");
  };

  if (!showPopup) {
    return null;
  }

  return (
    <div className="cookie-popup text-center">
      <div className="pop-body">
        <h5 className="pop-title">We use cookies!</h5>
        <p className="pop-text">
          We use cookies to ensure you the best experience. By using our website you agree to our{" "}
          <i>
            <Link to="/privacy" className="privacy-policy">
              Privacy Policy
            </Link>
          </i>
          .
        </p>
        <div className="d-flex justify-content-center mt-3">
          <button className="btn btn-outline-secondary btn-sm" style={{ marginRight: "10px" }} onClick={handleDecline}>
            Decline
          </button>
          <button className="btn btn-cookie-accept btn-sm" onClick={handleAccept}>
            Accept
          </button>
        </div>
      </div>
    </div>
  );
}

export default CookiePopup;
