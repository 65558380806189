import React from 'react';
import { Link } from 'react-router-dom';
import '../styles.css';


function Navbar() {
    return (
        <nav className="navbar navbar-expand-lg bg-light">
            <div className="container-fluid">
                <Link to="/" className="navbar-brand">
                    <img src={require("../images/TraxID-Logo-Color.png")} alt="TraxID Logo" className="logo" />
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item navbar-item">
                            <div className="dropdown">
                                <button className="btn dropdown-toggle nav-link navbar-link border-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Solutions
                                </button>
                                <ul className="dropdown-menu">
                                    <li>
                                        <Link to="/enterprise" className="dropdown-item nav-link navbar-link">
                                            Enterprise
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/standard" className="dropdown-item nav-link navbar-link">
                                            Small Business
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li className="nav-item navbar-item">
                            <Link to="/contact" className="nav-link navbar-link">
                                Contact
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}


export default Navbar;
