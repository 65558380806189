import React, { useState } from 'react';
import '../styles.css';
import AdminNavbar from './AdminNavbar';
import AdminSidebar from './AdminSidebar';
import AdminListBoard from './AdminListBoard';

export default function AdminPage() {
    const [activeCategory, setActiveCategory] = useState("");
    const [activeButton, setActiveButton] = useState("");
    const [newStatusSpan, setNewStatusSpan] = useState({});
    const [disableButtons, setDisableButtons] = useState(false);


    return (
        <>
            <AdminNavbar setActiveCategory={setActiveCategory} setActiveButton={setActiveButton} disableButtons={disableButtons} />
            <div className="row">
                <div className="col-md-3">
                    <AdminSidebar activeCategory={activeCategory} setActiveButton={setActiveButton} newStatusSpan={newStatusSpan} setNewStatusSpan={setNewStatusSpan} disableButtons={disableButtons} />
                </div>
                <div className="col-md-8">
                    <AdminListBoard activeCategory={activeCategory} activeButton={activeButton} setNewStatusSpan={setNewStatusSpan} setDisableButtons={setDisableButtons} />
                </div>
            </div>

        </>
    );
}