import React from 'react';
import { Link } from 'react-router-dom';
import '../styles.css';
import ContactForm from './ContactForm';
import Footer from './Footer';


function DemoLanding() {
    return (
        <>
            {/* black back ground */}
            <div className="container-fluid demo-halfBlack-bg">
                <div className="row">
                    <div className="col-xl-3 col-lg-2 col-md-1 col-sm-1">
                    </div>
                    <div className="col-xl-6 col-lg-8 col-md-10 col-sm-10 text-center mt-5 mb-3">
                        <img src={require("../images/TraxField-Logo-Color.png")} alt="TraxField-Logo" className="img-fluid mt-3 logo" />
                    </div>
                </div>

                <div className="row text-center">
                    <div className="col-xl-2 col-lg-2 col-md-1 col-sm-1"></div>
                    <div className="col-xl-8 col-lg-8 col-md-10 col-sm-10">
                        <h2 className="header mt-3 mb-4 landing-header">
                            Learn How You Can Provide Professional Service Levels Like Your Larger Competitors but <span className="orange-text">Without the Financial Burden</span>
                        </h2>
                    </div>
                </div>

                {/* part black background */}
                <div className="row">
                    <div className="col-xl-2 col-lg-1"></div>
                    <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 col-xs-12 mb-md-4 mb-sm-1 mb-xs-1 p-3">
                        <div className="text-center">
                            <img src={require("../images/FieldVideo.png")} alt="FieldVideo" className="img-fluid demolanding-img" />
                        </div>
                        <p className="mt-2">Our <span className="orange-text">free demo</span> illustrates what's capable with TraxField on your side. In one week, you can use our ready-to-use templates for OCTG inspection or we can create customizable reports tailored to your operations.
                        </p>
                        <div className="row">
                            <div className="col-lg-1"></div>
                            <div className="col-lg-10 col-md-12 col-sm-12 col-xs-12">
                                <div className="row">
                                    <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                                        <span className="orange-text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                        </svg></span> Effortlessly design reports + checklists
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                                        <span className="orange-text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                        </svg></span> Capture visuals and notes
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                                        <span className="orange-text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                        </svg></span> Cloud-based access
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                                        <span className="orange-text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                        </svg></span> Streamline invoice approval
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                                        <span className="orange-text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                        </svg></span> Effortless communication
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 mt-md-5">
                        <div style={{ background: 'white', borderRadius: '5%', padding: '10px 20px' }} className="mt-md-5 mt-sm-1 mt-xs-1">
                            <ContactForm selectedReq="demo" selectedInterest="standard" hideDdl={true} />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-2 col-md-2 col-sm-1 col-xs-1"></div>
                    <div className="col-lg-8 col-md-8 col-sm-10 col-xs-10 text-center mt-3">
                        <p>Not a small business? Learn more about our
                            <Link to="/enterprise" className="orange-text no-underline"> enterprise solution</Link>.
                        </p>
                    </div>
                </div>
            </div>
        <Footer />
        </>
    );

}

export default DemoLanding;