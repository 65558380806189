import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdminAPI } from '../apis/api/traxid/AdminAPI';

const CreateAccount = () => {
    // State to hold create account form data
    const [accountData, setAccountData] = useState({
        email: '',
        password: '',
        first_name: '',
        last_name: '',
        display_name: '',
        last_login:null,
        security_level: 'manager', // Default to 'manager', you can change as needed
        active_flag: true,
    });

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    // Function to handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAccountData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        handleFormInteraction();
    };
    const handleFormInteraction = () => {
        setSuccessMessage('');
        setErrorMessage('');
    };
    // Function to handle form submission
    const handleCreateAccount = async (e) => {
        e.preventDefault();

        try {
            // Make API call to create account endpoint
            await AdminAPI.createAccount(accountData);
            setSuccessMessage('Account created successfully. You will be redirected to Login in 10 seconds!');
            // Redirect to login page after 10 seconds
            setTimeout(() => {
                navigate('/login');
            }, 10000);

            // Redirect or perform any other actions after successful account creation
            // You can use react-router-dom for navigation
        } catch (error) {
            if (error.code === 'ECONNABORTED') {
                setErrorMessage('The request timed out. Please try again.');
            } else {
                // Handle other errors
                console.error('Account creation failed', error);
                setErrorMessage('An unexpected error occurred.');
            }
        }
    };

    return (
        <div className="row mt-3">
            <div className="col-md-1"></div>
            <div className="col-md-10 mt-2">
                <h2>Create Account</h2>
                <form onSubmit={handleCreateAccount}>
                    <div className="mb-3">
                        <label htmlFor="inputEmail">Email</label>
                        <input
                            type="email"
                            name="email"
                            className="form-control"
                            value={accountData.email}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="inputPassword">Password</label>
                        <input
                            type="password"
                            name="password"
                            className="form-control"
                            value={accountData.password}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="inputFirstName">First Name</label>
                        <input
                            type="text"
                            name="first_name"
                            className="form-control"
                            value={accountData.firstName}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="inputLastName">Last Name</label>
                        <input
                            type="text"
                            name="last_name"
                            className="form-control"
                            value={accountData.lastName}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="inputDisplayName">Display Name</label>
                        <input
                            type="text"
                            name="display_name"
                            className="form-control"
                            value={accountData.displayName}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="inputSecurityLevel">Security Level</label>
                        <select
                            name="security_level"
                            className="form-control"
                            value={accountData.securityLevel}
                            onChange={handleInputChange}
                        >
                            <option value="manager">Manager</option>
                            <option value="sales">Sales</option>
                            <option value="dev">Dev</option>
                        </select>
                    </div>
                    <div className="text-center mt-5">
                        <button type="submit" className="btn btn-primary">Create Account</button>
                    </div>
                </form>
                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                {successMessage && <div className="alert alert-success">{successMessage}</div>}
            </div>

        </div>
    );
};

export default CreateAccount;
