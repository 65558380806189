import React from 'react';
import { Link } from 'react-router-dom';
import '../styles.css';
import Footer from './Footer';

function Homepage() {
    return (
        <div>
            <div className="container-fluid darkbg">
                <div className="container-fluid">
                    <div className="row text-center mb-4">
                        <div className="col-lg-2 col-md-1"></div>
                        <div className="col-lg-8 col-md-10 col-sm-12 col-xs-12 mt-4">
                            <h2 className="header mt-4">
                                Powering Oil & Gas Operations with <span className="orange-text">Intuitively</span> Customized Software
                            </h2>
                        </div>

                    </div>

                    <div className="row ">
                        <div className="col-md-4"></div>
                        <div className="col-md-4 d-flex justify-content-center align-items-center">
                            <Link to="/demo">
                                <button id="btn_watch" type="button" className="btn btn-lg btndemo">Watch Demo</button>
                            </Link>
                        </div>
                    </div>
                </div>
                {/*tablet images*/}
                <div className="container-fluid position-relative">
                    <div className="row">
                        <div className="col-md-12 text-center position-relative parent mt-5">
                            <img src={require("../images/GrayBlob-1.png")} alt="Background" className="img-fluid image1" />
                            <img src={require("../images/Tablets.png")} alt="Overlay" className="img-fluid image2" />
                        </div>
                    </div>
                </div>
                    {/*trusted*/}
                <div className="container-fluid">
                    <div className="row text-center">
                        <div className="col-md-12 mt-5">
                            <p className="text-white fs-5 md-text">Trusted in the U.S. and Internationally</p>
                        </div>
                    </div>
                </div>
                {/*trusted logos*/}
                <div className="container-fluid">
                    <div className="row text-center">
                        <div className="col-md-12 mb-4">
                            <img src={require("../images/ForceLogo-White-Vertical.svg.png")} alt="Force Logo" className="forceLogo" />
                            <img src={require("../images/IOSLogo-White.png")} alt="IOS Logo" className="IOSLogo" />
                        </div>
                    </div>
                </div>
            </div>

            {/*end of black with tablet section*/}

            <div className="container-fluid">
                <div className="row mt-4 mb-4">
                    <div className="col-lg-2 col-md-1"></div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                        <div className="left-content">
                            <br />
                            <h2 className="header">Invest Your Resources Where It’s Needed</h2>
                            <br /><br />
                            <p className="content-font">
                                <span className="subheader fs-5">Simple to Use</span><br />
                                We build simple-to-use and cost-effective solutions to solve your business needs without spending an excessive amount of time in learning how to use the system, and without the rigid software processes as many ERP systems do.
                            </p>
                            <br />
                            <p className="content-font">
                                <span className="subheader fs-5">Experience in Your Corner</span><br />
                                With decades of hardware and software experience, our solutions are proven to manage end-to-end business processes of large oilfield equipment inspection operations internationally.
                            </p>
                            <br />
                            <p className="content-font">
                                <span className="subheader fs-5">Streamline Team Communication</span><br />
                                Inspection reports and field tickets can be downloaded from the cloud as soon as they are synced up from the field. Your team can save time by having access to one main source. From tablets, laptops, or desktop computers, you can keep everyone in the loop.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 text-center">
                        <div className="right-content">
                            <img src={require("../images/TraxNetwork.png")} alt="TraxNetwork" className="img-fluid home-2-image" />
                        </div>
                    </div>
                </div>
            </div>

            {/*Our solutions*/}

            <div className="container-fluid text-center">
                <div className="row mt-2 mb-2">
                    <br />
                    <h2 className="lg-text header">Our Solutions</h2>
                </div>
                <div className="row mb-4">
                    <div className="col-xl-2 col-lg-1"></div>
                    <div className="col-xl-8 col-lg-10 col-md-12 col-sm-12 col-xs-12">
                        <div className="row row-cols-1 row-cols-md-2 g-1 card-container">
                            <div className="col mt-3">
                                <div className="card h-100">
                                    <h3 className="subheader" id="solution_standard">Standard</h3>
                                    <div className="card-body">
                                        <div className="solutions_icon_group mt-3">
                                            <img className="solutions_icon" src={require("../images/TraxField-Icon-Color.png")} alt="TraxField Icon" />
                                            <img className="solutions_icon" src={require("../images/TraxField-Logo-Color.png")} alt="TraxField Logo" />
                                        </div>
                                        <div className="card-text">
                                            <h5>Quick to Deploy</h5>
                                            <p>Start by choosing one of our ready-to-use templates for OCTG inspection. Setting up a new report can be done as quickly as a week.</p>
                                            <p>
                                                <Link to="/standard" className="card_link">Explore our templates →</Link>
                                            </p>

                                            <h5>Custom Reports</h5>
                                            <p>We can customize inspection or work templates and reports for your operations the way you use them.</p>
                                        </div>
                                    </div>
                                    <div className="card-footer align-self-stretch">
                                        <h5>Who is it for?</h5>
                                        <p>TraxField is for small companies who want to provide professional service levels as their larger competitors but without the financial burden.</p>
                                        <p>
                                            <Link to="/standard" className="card_link">Learn More</Link>
                                        </p>
                                        <br />
                                    </div>
                                </div>
                            </div>

                            <div className="col mt-3">
                                <div className="card h-100">
                                    <h3 className="subheader" id="solution_enterprise">Enterprise</h3>
                                    <div className="card-body">
                                        <div className="solutions_icon_group mt-3">
                                            <img className="solutions_icon" src={require("../images/Intellispect-Icon-Color.png")} alt="Intellispect Icon" />
                                            <img className="solutions_icon" src={require("../images/TraxClient-Icon-Color.png")} alt="TraxClient Icon" />
                                            <img className="solutions_icon" src={require("../images/TraxSales-Icon-Color.png")} alt="TraxSales Icon" />
                                            <img className="solutions_icon" src={require("../images/TraxPlan-Icon-Color.png")} alt="TraxPlan Icon" />
                                            <img className="solutions_icon" src={require("../images/TraxPurchase-Icon-Color.png")} alt="TraxPurchase Icon" />
                                        </div>
                                        <div className="card-text">
                                            <h5>Custom Modules</h5>
                                            <p>We personalize our key modules to create a custom solution for you.</p>
                                            <p>
                                                <Link to="/enterprise" className="card_link">Explore our key modules →</Link>
                                            </p>

                                            <h5>Real Time Data</h5>
                                            <p>The enterprise solution manages field and shop operations in different geographical locations and time zones. Work can be created in the field or assigned from the central office.</p>
                                        </div>

                                    </div>
                                    <div className="card-footer align-self-stretch">
                                        <h5>Who is it for?</h5>
                                        <p>Our enterprise solution is custom built for large companies who need to manage multiple plants and remote locations. It’s designed to enable businesses to manage their diversified operations from start to finish.</p>
                                        <p>
                                            <Link to="/enterprise" className="card_link">Learn More</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    );
}

export default Homepage;