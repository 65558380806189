import { apiClient } from '../../utils/instances';

const URI = '/api/businesstypes';

export const BusinessTypeAPI = {
    getAllBusinessTypes: async () => {
        try {
            const response = await apiClient.get(URI);
            return response.data;
        } catch (error) {
            console.error('API [getAllBusinessTypes] Error:', error);
            throw error;
        }
    },

    addBusinessType: async (newType) => {
        try {
            const response = await apiClient.post(URI, newType);
            return response.data;
        } catch (error) {
            console.error('API [addBusinessType] Error:', error);
            throw error;
        }
    },

    updateBusinessType: async (id, updatedBusinessType) => {
        try {
            const response = await apiClient.put(`${URI}/${id}`, updatedBusinessType);
            return response.data;
        } catch (error) {
            console.error('API [updateBusinessType] Error:', error);
            throw error;
        }
    },

    deleteBusinessType: async (id) => {
        try {
            const response = await apiClient.delete(`${URI}/${id}`);
            return response.data;
        } catch (error) {
            console.error('API [deleteBusinessType] Error:', error);
            throw error;
        }
    }
};