import React, { useState, useEffect} from 'react';
import '../styles.css';
import { BusinessTypeAPI } from '../apis/api/traxid/BusinessTypeAPI';
import { RequestTypeAPI } from '../apis/api/traxid/RequestTypeAPI';
import { RequestAPI } from '../apis/api/traxid/RequestAPI';

export default function AdminSidebar({ activeCategory, setActiveButton, newStatusSpan, setNewStatusSpan, disableButtons }) {
    const [sidebarData, setSidebarData] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                let data = [];

                if (activeCategory === 'Requests') {
                    data = await RequestTypeAPI.getAllRequestTypes();
                    // Check for each request type if there are requests with status 'New'
                    const newStatus = {};
                    await Promise.all(
                        data.map(async (item) => {
                            const requests = await RequestAPI.getRequestsByType(item.value);
                            newStatus[item.value] = requests.some((request) => request.status === 'New');
                        })
                    );
                    setNewStatusSpan(newStatus);
                } else if (activeCategory === 'Drop Downs') {

                    data = [
                        { id: 1, value: 'businessType', text: 'Business Type' },
                        { id: 2, value: 'requestType', text: 'Request Type' },
                        { id: 3, value: 'interestSolutions', text: 'Interest Solutions' },
                    ];
                } else if (activeCategory === 'Contacts') {
                    data = await BusinessTypeAPI.getAllBusinessTypes();
                }

                setSidebarData(data);
            } catch (error) {
                console.error('Error fetching sidebar data:', error);
            }
        }

        fetchData();
    }, [activeCategory]);

    const handleButtonClick = (item) => {
        setActiveButton(item);
    }

    return (
        <>
            <ul className="nav flex-column sidebar">
                {sidebarData.map((item, index) => (
                    <li className="nav-item" key={index }>
                        <button
                            className="nav-link blue-text"
                            onClick={() => handleButtonClick(item)}
                            disabled={disableButtons}
                        >
                            {item.text}
                            {activeCategory === 'Requests' && newStatusSpan[item.value] && <span className="badge bg-sidebar rounded-pill ms-2">New</span>}
                        </button>
                    </li>
                ))}
            </ul>
        </>
    );
}