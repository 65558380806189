import { apiClient } from '../../utils/instances';

const URI = '/api/contactform';

export const ContactFormAPI = {
    createContactRequest: async (formData) => {
        try {
            const response = await apiClient.post(URI, formData);
            return response.data;
        } catch (error) {
            console.error('API [createContactRequest] Error:', error);
            throw error;
        }
    },
};
