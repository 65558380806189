import React from 'react';
import { Link } from 'react-router-dom';
import '../styles.css';
import Footer from './Footer';


function EnterprisePage() {
    return (
        <>
            {/* black back ground */}
            <div className="container-fluid darkbg">
                <div className="row mb-4">
                    <div className="col-xxl-2 col-xl-1 col-lg-1 col-md-1"></div>
                    <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-6 mt-4">
                        <img src={require("../images/TraxID-Icon-Color.png")} alt="traxid_icon" className="trax_icon mt-5" />
                        <h2 className="header mt-2 mb-3">
                            Your Business Shapes Our Software, Not The Other Way Around
                        </h2>
                        <p>
                            Unlike many other software providers, we don't try to fit your business needs into our software.
                            We work with your team to build our software to fit your business needs.
                        </p>
                        <Link to="/guide">
                            <button type="button" className="btn btn-md btn_solid">Download Our Free Guide</button>
                        </Link>
                        <Link to="/contact">
                            <button type="button" className="btn btn-md btn_border">Contact Us</button>
                        </Link>
                    </div>
                    <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-5 text-center mt-5">
                        <div className="d-flex align-items-center justify-content-center h-100">
                            <img src={require("../images/WarehouseBlob.png")} alt="WarehouseBlob" className="img-fluid mx-auto enterprise-1-blob" />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-3 col-md-2 col-sm-1">
                    </div>
                    <div className="col-lg-6 col-md-8 col-sm-10 align-items-center mt-3 mb-3">
                        <img src={require("../images/EnterpriseWorkflow.png")} alt="work flow" className="img-fluid enterprise-1-workflow mt-3" />
                    </div>
                    <div className="col-md-3">
                    </div>
                </div>

                <div className="row text-center">
                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-1"></div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-10">
                        <h2 className="header mt-2 mb-3">
                            How Our Enterprise Solution Works
                        </h2>
                        <p className="mb-5">
                            Our Enterprise Work Management (ERP) solutions are designed to help businesses handle all their operations smoothly from beginning to end. With our back office modules, operation managers and billing personnel can easily manage work from different locations and create customer invoices. Our solutions also allow executives to see real-time updates on productivity, revenue, and incidents, accessible from anywhere.
                        </p>
                    </div>
                    <div className="col-lg-3"></div>
                </div>

            </div>
            {/* end of black section */}
            {/* Purpose-built modules */}
            <div className="container-fluid content-font">
                <div className="row mt-4 text-center">
                    <div className="col-md-2 col-lg-3"></div>
                    <div className="col-md-8 col-lg-6">
                        <h2 className="header mt-2 mb-3">
                            Purpose-Built Modules
                        </h2>
                        <p className="mb-5">
                            We create modules as our client's needs emerge. We connect all teams and functions within your business operations. Below are some examples of modules we've already created.
                        </p>
                        <table className="mx-auto icon_table">
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="icon-container">
                                            <img src={require("../images/Module - Sales.png")} alt="sales module" className="enterprise_icons" />
                                            <p className="enterprise_icon_text">Sales</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="icon-container">
                                            <img src={require("../images/Module - Inventory.png")} alt="inventory module" className="enterprise_icons" />
                                            <p className="enterprise_icon_text">Inventory</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="icon-container">
                                            <img src={require("../images/Module - Work Order.png")} alt="workorders module" className="enterprise_icons" />
                                            <p className="enterprise_icon_text">Work Orders</p>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="icon-container">
                                            <img src={require("../images/Module - Servicing.png")} alt="servicing module" className="enterprise_icons" />
                                            <p className="enterprise_icon_text">Servicing</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="icon-container">
                                            <img src={require("../images/Module - Billing.png")} alt="billing module" className="enterprise_icons" />
                                            <p className="enterprise_icon_text">Billing</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="icon-container">
                                            <img src={require("../images/Module - Work Crew.png")} alt="work crew module" className="enterprise_icons" />
                                            <p className="enterprise_icon_text">Work Crew</p>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="icon-container">
                                            <img src={require("../images/Module - Analytics.png")} alt="Analytics module" className="enterprise_icons" />
                                            <p className="enterprise_icon_text">Analytics</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="icon-container">
                                            <img src={require("../images/Module - Customer Portal.png")} alt="Customer Portal module" className="enterprise_icons" />
                                            <p className="enterprise_icon_text">Customer Portal</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="icon-container">
                                            <img src={require("../images/Module - Purchases.png")} alt="Purchases module" className="enterprise_icons" />
                                            <p className="enterprise_icon_text">Purchases</p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <div className="col-md-2 col-lg-3"></div>
                </div>

            </div>

            {/* Learn More */}

            <div className="container-fluid text-center learn_more mt-4">
                <div className="row">
                    <div className="col-md-3" id="outer-etpr">
                        <div className="d-none d-md-block">
                            <img src={require("../images/BlueBlob-left.png")} alt="BlueBlob-left" className="blueblob float-left" style={{ marginTop: '30px', marginLeft: '-170px', height: '5rem' }} />
                        </div>

                    </div>
                    <div className="col-md-6">
                        <h2 className="header mt-5" style={{ color: 'white' }}>
                            Learn More
                        </h2>
                    </div>
                    <div className="col-md-3"> </div>
                </div>
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6">
                        <Link to="/guide">
                            <button type="button" className="btn btn-md btn_solid mb-5">Download Enterprise Guide</button>
                        </Link>
                        <Link to="/contact">
                            <button type="button" className="btn btn-md btn_border mb-5">Contact Us</button>
                        </Link>
                    </div>
                    <div className="col-md-3" id="outer-etpl">
                        <div className="d-none d-md-block">
                            <img src={require("../images/BlueBlob-right.png")} alt="BlueBlob-right" className="blueblob float-right" style={{ marginBottom: '30px', marginRight: '-170px', height: '5rem' }} />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );

}

export default EnterprisePage;