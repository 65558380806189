import { apiClient } from '../../utils/instances';
const URI = '/api/admin';

export const AdminAPI = {
    processLogin: async (loginRequest) => {
        try {
            const response = await apiClient.post(`${URI}/login`, loginRequest);
            return response.data;
        } catch (error) {
            console.error('API [processLogin] Error:', error);
            throw error;
        }
    },

    createAccount: async (newAdmin) => {
        try {
            console.log('Request Payload:', JSON.stringify(newAdmin)); // Log the payload
            const response = await apiClient.post(`${URI}/register`, newAdmin);
            return response.data;
        } catch (error) {
            console.error('API [createAccount] Error:', error);
            throw error;
        }
    },

    updateAdmin: async (admin) => {
        try {
            const response = await apiClient.post(`${URI}/update-admin`, admin);
            return response.data;
        } catch (error) {
            console.error('API [updateAdmin] Error:', error);
            throw error;
        }

    },

};