import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Navbar from './Components/Navbar';
import HomePage from './Components/Homepage';
import AboutPage from './Components/AboutPage';
import EnterprisePage from './Components/EnterprisePage';
import StandardPage from './Components/StandardPage';
import ScrollToTop from './Components/ScrollToTop';
import EnterpriseLanding from './Components/EnterpriseLanding';
import DemoLanding from './Components/DemoLanding';
//import PageNotFound from './Components/PageNotFound';
import CookiePopup from './Components/CookiePopup';
import PrivacyPolicy from './Components/PrivacyPolicy';
import AdminPage from './Components/AdminPage';
import Login from './Components/Login';
import CreateAccount from './Components/CreateAccount';

function App() {
    return (
        <BrowserRouter basename="/">
            <Navbar />
            <ScrollToTop />
            <Routes>
                <Route exact path="/" element={<HomePage />}> </Route>
                <Route exact path="/contact" element={<AboutPage />}></Route>
                <Route exact path="/enterprise" element={<EnterprisePage />} />
                <Route exact path="/standard" element={<StandardPage />} />
                <Route exact path="/guide" element={<EnterpriseLanding />} />
                <Route exact path="/demo" element={<DemoLanding />} />
                <Route exact path="/privacy" element={<PrivacyPolicy />} />
                <Route exact path="/admin" element={<AdminPage />} />
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/create-account" element={<CreateAccount />} />
                <Route path="*" element={<HomePage />} />
            </Routes>
            <CookiePopup />
        </BrowserRouter>
  );
}

export default App;
