import React, { useState} from 'react';
import { Link } from 'react-router-dom';
import '../styles.css';
import Footer from './Footer';


function StandardPage() {

    // Initialize state to track the expanded state of list items
    const [expandedItems, setExpandedItems] = useState([true, false, false, false, false]);

    // Function to toggle the expanded state of a list item
    const toggleItem = (index) => {
        const updatedExpandedItems = [...expandedItems];
        updatedExpandedItems[index] = !updatedExpandedItems[index];
        setExpandedItems(updatedExpandedItems);
    };

    const listItems = [
        {
            title: 'Effortlessly Design Reports + Checklists',
            content: (
                <>
                    Create personalized reports for inspections and generate field tickets directly.
                    <br />
                    Customize checklists in the field and build component lists for seamless inspections.
                </>
            ),
        },
        {
            title: 'Capture Visuals and Notes',
            content: (
                <>
                    Easily add pictures, documents, and inspection notes during field operations.
                    <br />
                    Sync to management & customers.
                </>
            ),
        },
        {
            title: 'Cloud-Based Access',
            content: (
                <>
                    Download inspection reports and field tickets from the cloud as soon as they are synced from the field.
                    <br />
                    Instantly search reports & pictures.
                </>
            ),
        },
        {
            title: 'Streamline Invoice Approval',
            content: (
                <>
                    Conveniently approve invoices through the cloud.
                    <br />
                    Customers review reports, view pictures, and approve invoices in their cloud account.
                </>
            ),
        },
        {
            title: 'Effortless Communication',
            content: 'Email reports to users and customers while notifying customers for invoice approvals.',
        },
    ];
    return (
        <>
            {/* black back ground */}
            <div className="container-fluid darkbg">
                <div className="row mb-4">
                    <div className="col-xxl-2 col-xl-1 col-lg-1 col-md-1"></div>
                    <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-6 mt-4">
                        <img src={require("../images/TraxField-Icon-Color.png")} alt="traxField_icon" className="trax_icon mt-5" />
                        <h2 className="header mt-3 mb-3">
                            Revolutionizing Inspection and Field Workflow for <span className="orange-text">Small Businesses</span>
                        </h2>
                        <p>
                            TraxField is for small companies who want to<br />provide professional service levels as their larger<br />competitors but without the financial burden.
                        </p>
                        <Link to="/demo">
                            <button type="button" className="btn btn-md btn_solid">Watch Demo</button>
                        </Link>

                        <Link to="/contact">
                            <button type="button" className="btn btn-md btn_border">Contact Us</button>
                        </Link>
                    </div>
                    <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-5 text-center mt-5">
                        <div className="d-flex align-items-center justify-content-center h-100">
                            <img src={require("../images/FieldBlob.png")} alt="FieldBlob" className="img-fluid mx-auto standard-1-blob" />
                        </div>
                    </div>
                </div>


                <div className="row mb-2">
                    <div className="col-xl-2 col-lg-2 col-md-1"></div>
                    <div className="col-xl-5 col-lg-8 col-md-8">
                        <h2 className="header mt-3">
                            How TraxField Works
                        </h2>
                        <p>Click to explore how TraxField can add value to your inspection business.</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-2 col-md-1"></div>
                    <div className="col-lg-8 col-md-10 mb-5">
                        <ul className="list-group custom-list-group">
                            {listItems.map((item, index) => (
                                <li
                                    key={index}
                                    className={`list-group-item ${expandedItems[index] ? 'active' : ''}`}
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapse-${index}`}
                                    onClick={() => toggleItem(index)} // Toggle the item when clicked
                                >
                                    <h5 className="subheader">
                                        <span>{item.title}</span>
                                        <span className="float-end">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi ${expandedItems[index] ? 'bi-caret-up-fill' : 'bi-caret-down-fill'}`} viewBox="0 0 16 16">
                                                <path d={expandedItems[index] ? "m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" : "M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"} />
                                            </svg>
                                        </span>
                                    </h5>

                                    <div className={`collapse ${expandedItems[index] ? 'show' : ''}`} id={`collapse-${index}`}>
                                        <p>{item.content}</p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

            </div>
            {/* end of black section */}
            {/* We don't waste time */}
            <div className="container-fluid">
                <div className="row mt-5 mb-2">
                    <div className="col-xl-1 col-md-1"></div>
                    <div className="col-xl-5 col-lg-10 col-md-10 col-sm-12 content-font">
                        <h2 className="header mt-2 mb-3">
                            We don't Waste Time
                        </h2>
                        <p className="mb-3">
                            Start by choosing one of our ready-to-use templates for OCTG inspection. All we need is your company logo and contact information for the report header. Report setup can be completed in as little as a week.
                        </p>
                        <div>
                            <h5 className="subheader">
                                Ready-to-Use Reports
                            </h5>
                            <table className="table custom-table">
                                <tbody>
                                    <tr className="tb-row">
                                        <td>Tool inspection</td>
                                        <td>Drill pipe inspection</td>
                                    </tr>
                                    <tr>
                                        <td>Standard inspection</td>
                                        <td>Hevi-Wate drill pipe inspection</td>
                                    </tr>
                                    <tr>
                                        <td>Dimensional inspection</td>
                                        <td>Casing Tubing inspection</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <h5 className="subheader">
                                Custom Reports
                            </h5>
                            <p className="mb-5">
                                We offer customization for inspection or work templates and reports tailored to your operations. To get started, simply provide sample reports, existing forms, or sketches of the desired report format.
                            </p>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 mt-auto mb-5">
                        <div className="row justify-content-center">
                            <div className="col-12 text-center">
                                <div className="imgTabletField-container">
                                    <img src={require("../images/SampleReport_Tablet.png")} alt="TraxTablet-Field" className="img-fluid imgTabletField" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {/* Get started today */}
            <div className="container-fluid text-center learn_more mt-4">
                <div className="row">
                    <div className="col-md-3" id="outer-stdr">
                        <div className="d-none d-md-block">
                            <img src={require("../images/BlueBlob-left.png")} alt="BlueBlob-left" className="float-left" style={{ marginTop: '30px', marginLeft: '-170px', height: '5rem' }} />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h2 className="header mt-5" style={{ color: 'white' }}>
                            Get Started Today
                        </h2>
                    </div>
                    <div className="col-md-3"> </div>
                </div>
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6">
                        <Link to="/demo">
                            <button type="button" className="btn btn-md btn_solid mb-5">Watch Demo</button>
                        </Link>
                        <Link to="/contact">
                            <button type="button" className="btn btn-md btn_border mb-5">
                                Contact Us
                            </button>
                        </Link>
                    </div>
                    <div className="col-md-3" id="outer-stdl">
                        <div className="d-none d-md-block" >
                            <img src={require("../images/BlueBlob-right.png")} alt="BlueBlob-right" className="float-right" style={{ marginBottom: '30px', marginRight: '-170px', height: '5rem' }} />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );

}

export default StandardPage;