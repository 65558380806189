import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles.css';


const categories = ["Requests", "Contacts", "Drop Downs"];
export default function AdminNavbar({ setActiveCategory, setActiveButton, disableButtons }) {
    const [displayName, setDisplayName] = useState('');
    const [activeCategoryNav, setActiveCategoryNav] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        // Check if the user is logged in
        const token = localStorage.getItem('adminToken');
        const adminData = localStorage.getItem('adminData');

        if (!(token && adminData)) {
            // User is not logged in, navigate to login page
            navigate("/login");
        } else {
            // User is logged in
            // Parse adminData JSON
            const admin = JSON.parse(adminData);

            // Set display_name
            setDisplayName(admin.display_name);
        }
    }, []); // Empty dependency array means this effect runs once after the initial render

    const handleLogout = () => {
        localStorage.removeItem('adminToken');
        localStorage.removeItem('adminData');
        setDisplayName('');
        navigate("/login");
    }

    const handleCategoryClick = (category) => {      
        setActiveCategoryNav(category);
        setActiveCategory(category);
        setActiveButton("");
    }

    return (
        <>
            <nav className="navbar navbar-expand-lg bg-body-tertiary">
                <div className="container-fluid d-flex justify-content-between">
                    <button className="navbar-brand admin-nav" href="#">Admin Dashboard</button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            {categories.map((category, index) => (
                                <li className="nav-item" key={index}>
                                    <button
                                        className={`nav-link admin-nav-link ${activeCategoryNav === category ? 'active' : ''}`}
                                        onClick={() => handleCategoryClick(category)}
                                        disabled={disableButtons}
                                    >
                                        {category}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="d-flex align-items-center nav-login">
                        {displayName && (
                            <>
                                <span className="nav-link" style={{ marginRight: '8px' }}>Hi, {displayName}!</span>
                                <button className="btn btn-link nav-link admin-nav-link" onClick={handleLogout}>Logout</button>
                            </>
                        )}
                    </div>
                </div>
            </nav>
        </>
    );
}
