import React, { useState, useEffect} from 'react';
import '../styles.css';
import { ContactAPI } from '../apis/api/traxid/ContactAPI';
import Pagination from './Pagination.js';

export default function ContactTable({ activeCategory, activeButton }) {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch contacts based on the activeButton's corresponding value
                const contacts = await ContactAPI.getContactsByBusinessType(activeButton.value);
                setData(contacts);
            } catch (error) {
                console.error('Error fetching contact data:', error);
            }
        };

        if (activeButton) {
            fetchData();
        }
    }, [activeButton]);

    // Get current items based on current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(data.length / itemsPerPage);

    const onPageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    return (
        <>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">First Name</th>
                        <th scope="col">Last Name</th>
                        <th scope="col">Company</th>
                        <th scope="col">Website</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone</th>
                    </tr>
                </thead>
                <tbody className="table-group-divider">
                    {currentItems.length > 0 ? (
                        currentItems.map((item, index) => (
                            <tr key={index}>
                                <td>{item.first_name}</td>
                                <td>{item.last_name}</td>
                                <td>{item.company_name}</td>
                                <td>{item.company_web}</td>
                                <td>{item.email}</td>
                                <td>{item.phone}</td>
                            </tr>
                        ))
                    ) : activeButton !== "" && (
                        <tr>
                            <td colSpan="3">No data available for the selected type.</td>
                        </tr>
                    )}
                </tbody>
            </table>
            {data.length > 0 && (
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={onPageChange}
                />
            )}
        </>
    );
}