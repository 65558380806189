import { apiClient } from '../../utils/instances';

const URI = '/api/requestdetails';

export const RequestDetailsAPI = {
    getRequestDetails: async (id) => {
        try {
            const response = await apiClient.get(`${URI}/${id}`);
            return response.data;
        } catch (error) {
            console.error('API [getRequestDetails] Error:', error);
            throw error;
        }
    },
};