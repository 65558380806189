import React from "react";
import "../styles.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="container-fluid footer">
      <div className="row">
        <div className="col-lg-1 col-md-1"></div>
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 mt-md-5 mt-3 text-center text-md-start">
          <img src={require("../images/TraxID-Icon-Color.png")} alt="traxid_icon" className="footer_icon" />
          <p className="footer_logo_text">
            Powering oil & gas operations <br />
            with intuitvely customized software.
          </p>
        </div>
        <div className="col-lg-4 col-md-4"></div>

        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 mt-md-5 mt-2 text-center text-md-end">
          <div className="row">
            <div className="col-6">
              <p className="footer_header">Solutions</p>
              <Link to="/standard" className="footer_link">
                Standard
              </Link>{" "}
              <br />
              <Link to="/enterprise" className="footer_link">
                Enterprise
              </Link>
            </div>
            <div className="col-6">
              <p className="footer_header">Contact</p>
              <Link to="/demo" className="footer_link">
                Watch Demo
              </Link>{" "}
              <br />
              <a href="https://www.linkedin.com/company/traxid" className="footer_link">
                LinkedIn
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-lg-11 col-md-11 col-sm-12 col-xs-12 text-center text-md-end">
          <a href="mailto:info@traxid.com">
            <img src={require("../images/Email.png")} alt="email" className="footer_ifont" />
          </a>
          <a href="https://www.linkedin.com/company/traxid">
            <img src={require("../images/LinkedIn.png")} alt="linkedin" className="footer_ifont" />
          </a>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-lg-1 col-md-1"></div>
        <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 text-center text-md-start">
          <p>
            <i>
              <Link to="/privacy" className="privacy-policy">
                Privacy Policy
              </Link>
            </i>{" "}
            &nbsp;{" "}
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-c-circle" viewBox="0 0 16 16">
              <path d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8Zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM8.146 4.992c-1.212 0-1.927.92-1.927 2.502v1.06c0 1.571.703 2.462 1.927 2.462.979 0 1.641-.586 1.729-1.418h1.295v.093c-.1 1.448-1.354 2.467-3.03 2.467-2.091 0-3.269-1.336-3.269-3.603V7.482c0-2.261 1.201-3.638 3.27-3.638 1.681 0 2.935 1.054 3.029 2.572v.088H9.875c-.088-.879-.768-1.512-1.729-1.512Z" />
            </svg>{" "}
            2023, TraxID, LLC.{" "}
          </p>
        </div>
        <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 text-center text-md-end">
          <p>
            <a href="https://maps.app.goo.gl/CHqr3BgaBAE2yqcN8" className="footer_link">
              12100 Ford Road, #302, Dallas, TX 75234
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
