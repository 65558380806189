import { apiClient } from '../../utils/instances';

const URI = '/api/contacts';

export const ContactAPI = {
    getAllContacts: async () => {
        try {
            // Send a GET request to retrieve all contacts
            const response = await apiClient.get(URI);

            // If the request is successful, return the response data
            return response.data;
        } catch (error) {
            // Handle any errors or exceptions here
            console.error('API [getAllContacts] Error:', error);
            throw error;
        }
    },

    getContactsByBusinessType: async (businessType) => {
        try {
            const response = await apiClient.get(`${URI}/${businessType}`);
            return response.data;
        } catch (error) {
            console.error('API [getContactsByBusinessType] Error:', error);
            throw error;
        }
    }
};