import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Assuming you're using react-router-dom for navigation
import { AdminAPI } from '../apis/api/traxid/AdminAPI';

const Login = () => {
    const [errorMessage, setErrorMessage] = useState('');
    // State to hold login form data
    const [loginData, setLoginData] = useState({
        email: '',
        password: '',
    });

    const navigate = useNavigate();

    // Function to handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setLoginData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setErrorMessage('');
    };

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await AdminAPI.processLogin(loginData);
            console.log('API Response:', response);

            if (response && response.admin) {
                // Successful login
                const { admin, token } = response;
                localStorage.setItem('adminToken', token);
                localStorage.setItem('adminData', JSON.stringify(admin));
            } else if (response && response.message) {
                // Unsuccessful login
                console.error('Login failed:', response.message);
                setErrorMessage(response.message);
            } else if (response && response.data && response.data.message) {
                // Check for a nested message property
                console.error('Login failed:', response.data.message);
                setErrorMessage(response.data.message);
            } else {
                // Unexpected API response
                console.error('Unexpected API response:', response);
            }

            // Redirect or perform any other actions after login
            navigate('/admin');
        } catch (error) {
            // Handle other errors
            console.error('Login failed', error);
            setErrorMessage(error.response?.data?.message || 'An unexpected error occurred during login.');
        }
    };


    return (
        <div>
            <div className="row mt-5 mb-5">
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-3 col-sm-1 col-xs-1"></div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-10 col-xs-10 mb-5">
                    <div className="login-panel">
                        <div className="login-content">
                            <h2 className="text-center">Login</h2>
                            <form onSubmit={handleLogin}>
                                <div className="mb-3">
                                    <label htmlFor="inputEmail1" className="form-label">Email</label>
                                    <input
                                        type="email"
                                        name="email"
                                        className="form-control"
                                        value={loginData.email}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="inputPassword1" className="form-label">Password</label>
                                    <input
                                        type="password"
                                        name="password"
                                        className="form-control"
                                        value={loginData.password}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                                <div className="text-center mt-5 mb-3">
                                    <button type="submit" className="btn btn-secondary">Login</button>
                                </div>
                            </form>
                            {errorMessage && <p className="alert alert-danger">{errorMessage}</p>}
                            {/* Link to the Create Account page */}
                            <p>Don't have an account? <Link to="/create-account">Create Account</Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
