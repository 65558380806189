import React from 'react';
import { Link } from 'react-router-dom';
import '../styles.css';

function PrivacyPolicy() {
    return (

        <div className="mt-5 mb-5 privacy-policy-panel text-center">
            <h1>TraxID, LLC Privacy Policy</h1>
            <p>This website employs cookies and related technologies to facilitate site operation and analytics. You have the option to provide consent for our utilization of these technologies, reject non-essential technologies, or further manage your preferences. If you choose to opt-out of sharing information related to these technologies with third parties, please select "Decline."</p>
            <h4>Contact Forms</h4>
            <p>We handle the information you furnish, including your name, email address, company affiliation, phone number, company website and any accompanying comments. As a business-to-business (B2B) entity, we undertake this processing to address your requests for information or resources. Additionally, in our legitimate interest, we collect information to establish potential business connections with you. Marketing communications may be directed to you based on the information submitted through these online forms. You can conveniently opt out of receiving future communications by using the provided opt-out link in the emails you receive. It's important to note that if you opt out but subsequently complete another form, you essentially cancel your opt-out status.</p>
            <Link to="/">
                <button className="btn btn_solid mt-3">Return to Homepage</button>
            </Link>
        </div>
    );
}


export default PrivacyPolicy;