import { apiClient } from '../../utils/instances';

const URI = '/api/requests';

export const RequestAPI = {
    getAllRequests: async () => {
        try {
            const response = await apiClient.get(`${URI}/all`);
            return response.data;
        } catch (error) {
            console.error('API [getAllRequests] Error:', error);
            throw error;
        }
    },

    getUnresolvedRequests: async () => {
        try {
            const response = await apiClient.get(`${URI}/unresolved`);
            return response.data;
        } catch (error) {
            console.error('API [getUnresolvedRequests] Error:', error);
            throw error;
        }
    },

    getRequestsByType: async (type) => {
        try {
            const response = await apiClient.get(`${URI}/${type}`);
            return response.data;
        } catch (error) {
            console.error('API [getRequestsByType] Error:', error);
            throw error;
        }

    },

    getRequestById: async (id) => {
        try {
            const response = await apiClient.get(`${URI}/id/${id}`);
            return response.data;
        } catch (error) {
            console.error('API [getRequestsById] Error:', error);
            throw error;
        }

    },

    updateRequest: async (id, request) => {
        try {
            const response = await apiClient.put(`${URI}/${id}`, request);
            return response.data;
        } catch (error) {
            console.error('API [updateRequest] Error:', error);
            throw error;
        }
    },
};