import React, { useState } from 'react';
import '../styles.css';
import RequestTable from './RequestTable';
import ContactTable from './ContactTable';
import DropDownTable from './DropDownTable';
import RequestDetails from './RequestDetails';

export default function AdminListBoard({ activeCategory, activeButton, setNewStatusSpan, setDisableButtons }) {

    const [selectedRequestId, setSelectedRequestId] = useState(null);
    let tableComponent;

    const handleRowClick = (requestId) => {
        // Set the selected request ID when a row is clicked
        setSelectedRequestId(requestId);
    };

    switch (activeCategory) {
        case 'Requests':
            tableComponent = (
                <>
                    {!selectedRequestId && (
                        <RequestTable
                            activeCategory={activeCategory}
                            activeButton={activeButton}
                            onRowClick={handleRowClick}
                            setNewStatusSpan={setNewStatusSpan}
                        />
                    )}
                    {selectedRequestId && (
                        <RequestDetails
                            requestId={selectedRequestId}
                            onClose={() => setSelectedRequestId(null)} // Close details component
                            setDisableButtons={setDisableButtons}
                        />
                    )}
                </>
            );
            break;

        case 'Contacts':
            tableComponent = (
                <ContactTable
                    activeCategory={activeCategory}
                    activeButton={activeButton}
                />
            );
            break;

        case 'Drop Downs':
            tableComponent = (
                <DropDownTable
                    activeButton={activeButton}
                />
            );
            break;

        default:
            tableComponent = null;

    }


    return (
        <div className="mt-3 mb-5">
            <h3>
                {activeCategory === "" && (
                    <>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-circle" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
                        </svg>
                        {' '}
                        Select a Category from the Navbar
                        <hr />
                    </>
                )}
                {activeCategory !== "" && activeButton === "" && (
                    <>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left-circle" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                        </svg>
                        {' '}
                        Select a Type from the Sidebar
                    </>
                )}
                {activeButton !== "" && activeButton.text}
            </h3>
            {tableComponent}
        </div>
    );
}
